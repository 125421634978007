import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import IDScan from "./IDScan";
import axios from "axios";
import { SCANNER_API_ROOT } from "./config";

import Amplify, { Auth } from "aws-amplify";

import moment from "moment";
import passportIcon from "./assets/images/passport_icon.svg";
import dlIcon from "./assets/images/dl_icon.svg";
import errorIcon from "./assets/images/error.svg";
import completedIcon from "./assets/images/completed.svg";

const ScanningSteps = (props) => {
  const { scanID } = useParams();
  const location = useLocation();
  const [awsUser, setAwsUser] = useState(null);
  const [config, setConfig] = useState({});
  const [action, setAction] = useState("waiting");
  const [steps, setSteps] = useState([]);
  const [showProgressTracker, setShowProgressTracker] = useState(false);
  const [s3Bucket, setS3Bucket] = useState("");
  const [idType, setIDType] = useState("");
  const [wsTimeout, setWsTimeout] = useState(true);
  const [wsRetries, setWsRetries] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [hideTop, setHideTop] = useState(false);
  const [finishSettings, setFinishSettings] = useState({});
  const [userID, setUserID] = useState("");
  const [partner, setPartner] = useState("");
  const [supportEmail, setSupportEmail] = useState("");

  const wsUpdate = (isWS) => {
    if (updateTimer) {
      window.clearTimeout(updateTimer);
      updateTimer = null;
      setLastUpdated(moment());
    }
  };

  let updateTimer = null;
  const checkLastUpdated = () => {
    const fifteenSecondsAgo = moment().subtract(15, "seconds");
    if (lastUpdated.isBefore(fifteenSecondsAgo)) {
      wsUpdate(true);
    }
  };

  const onWSMessage = (e) => {
    try {
      const data = JSON.parse(e.data);
      if (data) {
        if (data.type === "message" && data.message === "reload") {
          const fiveSecondsAgo = moment().subtract(5, "seconds");
          if (lastUpdated.isBefore(fiveSecondsAgo)) {
            wsUpdate(true);
          } else {
            if (!updateTimer) {
              updateTimer = window.setTimeout(() => {
                wsUpdate(true);
              }, 5000);
            }
          }
        } else if (data.type === "message") {
          const inner = JSON.parse(data.message);
          if (inner && inner.config) {
            const config = JSON.parse(inner.config);
            setConfig(config);
            if (config.customization && config.customization.finish) {
              const finish = config.customization.finish;
              if (finish.companion) {
                setFinishSettings(finish.companion);
              }
            }
          }
        }
      }
    } catch (err) {}
  };

  const startWS = (websocketServerLocation) => {
    window.scannerWS = new WebSocket(websocketServerLocation);
    window.scannerWS.onmessage = (e) => {
      onWSMessage(e);
    };
    window.scannerWS.onclose = () => {
      setWsRetries(wsRetries + 1);
      if (wsRetries >= 10) {
        window.location.reload();
      }
      setWsTimeout(
        setTimeout(() => {
          const wsRoute = process.env.REACT_APP_WS_ROUTE.replace(/\/$/, "");
          startWS("wss:" + wsRoute + "/scanner/" + scanID);
        }, 5000)
      );
    };
    window.scannerWS.onopen = () => {
      setWsRetries(0);
    };
  };

  let done = false;
  useEffect(() => {
    if (!scanID) {
      return;
    }

    axios
      .post("https:" + SCANNER_API_ROOT + "/scanner/start/" + scanID)
      .then((result) => {
        Amplify.configure({
          aws_project_region: result.data.data.region,
          aws_cognito_identity_pool_id: result.data.data.id_pool,
          aws_cognito_region: result.data.data.region,
          aws_user_pools_id: result.data.data.user_pool_id,
          aws_user_pools_web_client_id:
            result.data.data.user_pool_web_client_id,
          aws_user_files_s3_bucket: result.data.data.s3_bucket,
          aws_user_files_s3_bucket_region: result.data.data.region,
        });
        setS3Bucket(result.data.data.s3_bucket);
        setSteps(result.data.data.steps);
        setAction("select_id");
        setUserID(result.data.data.user_id);
        setPartner(result.data.data.partner);
        setSupportEmail(result.data.data.support_email);
        const token = result.data.data.token;
        const domain = "developer";
        const expiresIn = 3600;
        const user = { id: scanID };
        const identity_id = result.data.data.identity_id;
        return Auth.federatedSignIn(
          domain,
          {
            token,
            identity_id, // Optional
            expires_at: expiresIn * 1000 + new Date().getTime(), // the expiration timestamp
          },
          user
        )
          .then((cred) => {
            // If success, you will get the AWS credentials
            // console.log(cred);
            return Auth.currentAuthenticatedUser();
          })
          .then((user) => {
            setAwsUser(user);
            // console.log(user)
          })
          .catch((e) => {
            setAction("error");
          });
        // });
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length > 0
        ) {
          if (err.response.data.errors[0] === "not found") {
            setAction("not found");
          } else if (
            err.response.data.errors[0] === "scan has been completed"
          ) {
            setAction("completed");
          } else {
            // setAction("error")
            setAction("waiting");
          }
        } else {
          // setAction("error")
          setAction("waiting");
        }
      });

    let checkTimeoutInterval;
    if (!location.state || !location.state.noHandOff) {
      const wsRoute = process.env.REACT_APP_WS_ROUTE.replace(/\/$/, "");
      // need to implement sending/receiving messages from scanner websocket
      // scanner-api has websocket route at scanner/{user_id}
      if (!window.scannerWS) {
        startWS("wss:" + wsRoute + "/scanner/" + scanID);
      }
      checkTimeoutInterval = window.setInterval(() => {
        checkLastUpdated();
      }, 15000);
    }
    if (location.state && location.state.hideTop) {
      setHideTop(true);
    }

    return () => {
      done = true;
      if (checkTimeoutInterval) {
        window.clearInterval(checkTimeoutInterval);
      }
      if (window.scannerWS) {
        window.scannerWS.onclose = () => {};
        window.scannerWS.close();
        window.scannerWS = null;
      }
      if (wsTimeout) {
        window.clearTimeout(wsTimeout);
      }
    };
  }, [scanID, location]);

  useEffect(() => {
    if (location.state && location.state.customization) {
      setConfig({ customization: location.state.customization });
    }
  }, [location.state]);

  const finish = () => {
    axios
      .post("https:" + SCANNER_API_ROOT + "/scanner/finish/" + scanID)
      .then((result) => {
        setAction("finished");
      })
      .catch((err) => {
        setAction("finished");
      });
  };

  const updateTrackerUIState = (state) => {
    setShowProgressTracker(state);
  };

  let inside = <div />;
  let waiting = (
    <div>
      <div className="waiting-wrapper">
        <p className="courier-waiting-text"> Hang on, I'm loading... </p>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
  let finished = () => {
    if (window.parent) {
      window.parent.postMessage("finish", "*"); // TODO: Register domain on scan init and record on scan job.  when retrieivng scan job use that domain for the target origin
    }
    if (location.state && location.state.noHandOff) {
      return (
        <div className="thankyou-wrapper">
          <div className="thank-you-page-image"> </div>
          <h2 className="thank-you-text">
            {location.state.finishMessage
              ? location.state.finishMessage
              : "That's all!  We'll take you back to the checkout page."}
          </h2>
        </div>
      );
    } else {
      return (
        <div className="thankyou-wrapper" style={{ height: "100%" }}>
          <div className="thank-you-page-image"> </div>
          <h2
            className="courier-waiting-text"
            style={{ maxWidth: "80%", margin: "40px auto", color: "white" }}
          >
            {finishSettings.header ? finishSettings.header : "Ready to roll"}
          </h2>
          <div style={{ maxWidth: "80%", margin: "40px auto" }}>
            <h3 style={{ fontWeight: "bold", color: "white" }}>
              {finishSettings.body
                ? finishSettings.body
                : "Check back at your computer to continue."}
            </h3>
          </div>
        </div>
      );
    }
  };

  let selectID = () => {
    return (
      <div>
        <div className="waiting-wrapper">
          <p className="courier-waiting-text"> Select your ID Type</p>
          <div className="id-selector">
            <div
              onClick={() => {
                setIDType("id_card");
                setAction("scan_id");
              }}
            >
              <img src={dlIcon} />
              <div>Driver's License / ID Card</div>
            </div>
            <div
              onClick={() => {
                setIDType("passport");
                setAction("scan_id");
              }}
            >
              <img src={passportIcon} />
              <div>Passport</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const completed = () => {
    return (
      <div>
        <div className="waiting-wrapper">
          <p className="courier-waiting-text">You're done</p>
          <img className={"errorIcon"} src={completedIcon} />
          <div className={"errorText"}>
            You've completed this verification already.
          </div>
        </div>
      </div>
    );
  };
  const notFound = () => {
    return (
      <div>
        <div className="waiting-wrapper">
          <p className="courier-waiting-text">Something went wrong</p>
          <img className={"errorIcon"} src={errorIcon} />
          <div className={"errorText"}>
            This scan has expired, please restart your verification process.
          </div>
        </div>
      </div>
    );
  };
  const genericError = () => {
    return (
      <div>
        <div className="waiting-wrapper">
          <p className="courier-waiting-text">Something went wrong</p>
          <img className={"errorIcon"} src={errorIcon} />
          <div className={"errorText"}>
            An unexpected error has occurred, mind trying again in a minute or
            so?
          </div>
        </div>
      </div>
    );
  };
  switch (action) {
    case "not found":
      inside = notFound();
      break;
    case "completed":
      inside = completed();
      break;
    case "error":
      inside = genericError();
      break;
    case "select_id":
      inside = selectID();
      break;
    case "scan_id":
      inside = (
        <IDScan
          userID={userID}
          partner={partner}
          supportEmail={supportEmail}
          idType={idType}
          scanID={scanID}
          customization={config.customization}
          steps={steps}
          hideTop={hideTop}
          s3Bucket={s3Bucket}
          back={() => {
            setAction("select_id");
          }}
          sendResponse={finish}
          updateTrackerUIState={updateTrackerUIState}
          awsUser={awsUser}
        />
      );
      break;
    case "finished":
      inside = finished();
      break;
    case "waiting":
    default:
      inside = waiting;
  }

  return (
    <div className="appContainer">
      {config && config.customization && config.customization.css && (
        <link
          rel="stylesheet"
          type="text/css"
          href={config.customization.css}
        />
      )}
      {!hideTop && (
        <div className="topBar">
          <figure className="emjay-logo" />
        </div>
      )}
      {inside}
    </div>
  );
};

export default ScanningSteps;
