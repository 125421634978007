import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import 'fontsource-roboto';

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  navigator.mediaDevices.getUserMedia({video:true}).then( (stream) => {
    stream.getTracks().forEach(function(track) {
      if (track.readyState === 'live') {
        track.stop();
      }
    });
    window.streamSupported = true
  }).catch(function() {
    navigator.mediaDevices.getUserMedia = null
    window.streamSupported = false
  })
}else{
  navigator.mediaDevices.getUserMedia = null
  window.streamSupported = false
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);