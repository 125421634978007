import axios from 'axios';
import {
  USER_API_ROOT,
  SCANNER_API_ROOT,
} from '../config';

axios.defaults.headers['Content-Type'] = 'application/json';

export const setSessionIdHeader = (SESSION_ID) => {
  userService.defaults.headers.common['SESSION_ID'] = SESSION_ID;
  scannerService.defaults.headers.common['session-id'] = SESSION_ID;
};

export const setAuthHeader = (AUTH_TOKEN) => {
  userService.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  scannerService.defaults.headers.common['Authorization'] = AUTH_TOKEN;
};

// User service
export const userService = axios.create({
  baseURL: USER_API_ROOT
});

export const scannerService = axios.create({
  baseURL: SCANNER_API_ROOT
});

/**
 * @ SCANNER API CALLS
 */


/**
 * @ USER API CALLS
 */


export const sendText = (userID, mobileNum) => {
  return scannerService.post(
    "/scanner/send/" + userID,
    {mobile_num: mobileNum}
  )
}