const hostname = window && window.location && window.location.hostname;

let userApiHost;
let scannerApiHost;
let idScannerHost;

if (!!process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV !== '') {
  switch (process.env.REACT_APP_API_ENV) {
    case 'local':
      idScannerHost = 'jk-scanner.ngrok.io'
      userApiHost = 'user-api.local.theknollagency.com';
      scannerApiHost = 'jk-scanner-api.ngrok.io';
      break;
    case 'dev':
      userApiHost = 'dev-user-api.theknollagency.com';
      idScannerHost = 'dev-id.heyemjay.com';
      scannerApiHost = 'dev-scanner-api.heyemjay.com';
      break;
    case 'prod':
    default:
      userApiHost = 'user-api.heyemjay.com';
      idScannerHost = 'id.heyemjay.com';
      scannerApiHost = 'scanner-api.heyemjay.com';
      break;
  }
} else {
  if (hostname.includes('dev')) {
    userApiHost = 'dev-user-api.theknollagency.com';
    idScannerHost = 'dev-id.heyemjay.com';
    scannerApiHost = 'dev-scanner-api.heyemjay.com';
  } else {
    userApiHost = 'user-api.heyemjay.com';
    idScannerHost = 'id.heyemjay.com';
    scannerApiHost = 'scanner-api.heyemjay.com';
  }
}
// Google Analytics

export const USER_API_ROOT = `//${userApiHost}`;
export const SCANNER_API_ROOT = `//${scannerApiHost}`;
export const ID_SCANNER_ROOT = `//${idScannerHost}`;
