import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

import ScanningSteps from "./ScanningSteps";
import StartScanning from "./StartScanning";

const NoMatch = () => {
  window.location.href = "https://heyemjay.com";
  return null;
};
function App() {
  const [orientation, setOrientation] = React.useState(window.orientation);
  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setOrientation(window.orientation);
    });
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          <Route path="/scanner/:scanID" component={ScanningSteps} />
          <Route path="/:scanID" component={StartScanning} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
      {[90, -90].includes(orientation) && (
        <div
          style={{
            top: 0,
            left: 0,
            position: "absolute",
            width: "100%",
          }}
          className="appContainer"
        >
          <div className="thankyou-wrapper">
            <div className="rotate-image"> </div>
            <h2 className="thank-you-text">
              Rotate your device to continue
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}
export default App;
