import React from "react";
import Webcam from "react-webcam";
import CameraIcon from "./assets/images/camera.svg";

const videoConstraints = {
  facingMode: "user",
};

export default function FrontIDScan(props) {
  const webcamRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [tempImage, setTempImage] = React.useState("");
  const [legacyImage, setLegacyImage] = React.useState(null);

  const selectorClassname = loading
    ? "idScanSelector capture disabled"
    : "idScanSelector capture";
  const capture = React.useCallback(() => {
    setLoading(true);
    const imageSrc = webcamRef.current.getScreenshot();
    setTempImage(imageSrc);
    props.onFinish(imageSrc).catch((e) => this.setState({ error: "upload" }));
  }, [webcamRef]);
  const legacyUploadClassname = legacyImage
    ? "custom-recapture"
    : "custom-file-upload";
  const legacyFileUploadChange = (input, problem) => {
    if (input.target.files && input.target.files[0]) {
      const fr = new FileReader();
      fr.onload = function (e) {
        const img = e.target.result.toString();

        if (problem) {
          setLoading(true);
          setTempImage(img);
          props.onFinish(img);
        } else {
          setLegacyImage(img);
        }
      };
      fr.readAsDataURL(input.target.files[0]);
    }
  };

  const legacyCapture = React.useCallback(() => {
    setLoading(true);
    setTempImage(legacyImage);
    props.onFinish(legacyImage);
  }, [legacyImage]);

  const height = props.hideTop ? "calc(100vh - 90px)" : "calc(100vh - 270px)";

  return (
    <React.Fragment>
      <div className="close-icon" onClick={() => props.backToSelect()} />
      <div style={{ height: height, display: "flex", flexDirection: "column" }}>
        <h3>Take a selfie</h3>
        <div className="center-frame">
          <div className={"webcam-container selfie"}>
            {loading ? (
              <div>
                <img src={tempImage} className={"webcam-preview selfie"} />
                <div className={"webcam-loading-overlay"}>
                  <div>
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <h5>Processing...</h5>
                    <p>
                      This might take a moment, please do not close this window.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {window.streamSupported ? (
                  <Webcam
                    className={"webcam-preview selfie"}
                    audio={false}
                    ref={webcamRef}
                    screenshotQuality={1}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize
                    videoConstraints={videoConstraints}
                  />
                ) : (
                  <>
                    {legacyImage && (
                      <img
                        className={"webcam-preview-legacy"}
                        src={legacyImage}
                      />
                    )}
                    <label
                      htmlFor="file-upload"
                      className={legacyUploadClassname}
                    >
                      {!legacyImage && (
                        <img style={{ height: 80 }} src={CameraIcon} />
                      )}
                      {legacyImage
                        ? "Retake picture"
                        : "Launch camera to take a picture"}
                    </label>
                    <input
                      id="file-upload"
                      onChange={(e) => legacyFileUploadChange(e, false)}
                      type="file"
                      accept="image/*"
                      capture="user"
                    />
                  </>
                )}
                {/*<div className="passport-overlay">*/}
                {/*  <img src={PassportOverlay} />*/}
                {/*</div>*/}
              </div>
            )}
          </div>
          {window.streamSupported ? (
            <div
              className={selectorClassname}
              onClick={() => {
                if (loading) return;
                capture();
              }}
            >
              <p>Capture photo</p>
            </div>
          ) : (
            <div
              className={selectorClassname}
              onClick={() => {
                if (!legacyImage) return;
                if (loading) return;
                legacyCapture();
              }}
            >
              <p>Use photo</p>
            </div>
          )}
        </div>
      </div>

      <div className={"problems"}>
        Having Problems?
        <input
          type="file"
          accept="image/*"
          id="file-upload-button"
          hidden
          onChange={(e) => legacyFileUploadChange(e, true)}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <label htmlFor="file-upload-button" className={"idScanSelector"}>
          <p>Upload File</p>
        </label>
      </div>
    </React.Fragment>
  );
}
