import React, { Component } from "react";
import { LabelRecognizer } from "dynamsoft-label-recognizer";
import PassportIllustration from "./assets/images/passport_illustration.png";

import moment from "moment";
import CameraIcon from "./assets/images/camera.svg";
import VideoRecognizer from "./MRZVideoDecode/VideoRecognizer";
import { mrzParseThreeLine, mrzParseTwoLine } from "./mrzParser";
import "./license/dce";
import "./license/dlr";

export default class PassportScanner extends Component {
  state = {
    pause: false,
    loading: false,
    tempImage: "",
    error: "",
    ready: false,
    instruction: false,
    showToolTip: false,
  };

  pReader = null;
  toolTipTimer = null;

  async componentDidMount() {
    try {
      await LabelRecognizer.loadWasm();
      this.setState(
        { ready: true, instruction: window.streamSupported },
        async () => {
          this.pRecognizer = await LabelRecognizer.createInstance({
            runtimeSettings: "MRZ",
          });
        }
      );
    } catch (ex) {
      let errMsg;
      if (ex.message.includes("network connection error")) {
        errMsg =
          "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
      alert(errMsg);
    }
  }
  instruction = () => (
    <div className={"errorMessage"}>
      <p>
        Aim the viewfinder at the Machine Readable Zone (MRZ) of the ID page in
        your passport.
      </p>
      <img
        style={{ maxWidth: 300, width: "100%" }}
        src={PassportIllustration}
      />
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ instruction: false });
          if (window.streamSupported) {
            if (!this.state.warned && !this.toolTipTimer) {
              this.toolTipTimer = window.setTimeout(() => {
                this.toolTipTimer = null;
                this.setState({
                  warned: true,
                  loading: false,
                  showToolTip: true,
                });
              }, 15000);
            }
          }
        }}
      >
        <p>Got it</p>
      </div>
    </div>
  );
  uploadError = () => (
    <div className={"errorMessage"}>
      <p>Error uploading your passport.</p>
      <span>
        An error occurred while uploading your passport, please try again.
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );
  genericError = () => (
    <div className={"errorMessage"}>
      <p>Error reading your passport, please make sure:</p>
      <ul>
        <li>The image is free of glare</li>
        <li>No part of the MRZ is covered</li>
        <li>The texts are clear and not blurry</li>
      </ul>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  underageError = () => (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        We are not able to serve customers under the age of 21, or 18 if you
        have a medical recommendation
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  expirationError = () => (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        Looks like your Passport has expired, please scan a current document
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  handleBackToSelectView = () => {
    this.setState({ pause: true });
    this.props.backToSelect();
  };

  getErrorMessage = () => {
    switch (this.state.error) {
      case "underage":
        return this.underageError();
      case "upload":
        return this.uploadError();
      case "expired":
        return this.expirationError();
      default:
        return this.genericError();
    }
  };

  onScan = ({ result, canvas, manual }) => {
    if (this.toolTipTimer) {
      window.clearTimeout(this.toolTipTimer);
    }
    let parsed = {};
    let raw = "";
    if (!manual) {
      if (result.length === 2) {
        parsed = mrzParseTwoLine(...result.map((result) => result.text));
      } else if (result.length === 3) {
        parsed = mrzParseThreeLine(...result.map((result) => result.text));
      } else {
        return;
      }

      if (!parsed.birthday) {
        return;
      }
      raw = result.map((result) => result.text).join("\n");
      if (
        moment.utc().diff(moment.utc(parsed.birthday, "YYYYMMDD"), "years") < 18
      ) {
        this.setState({ loading: false, error: "underage" });
        return;
      }
      if (
        parsed.expiration_date &&
        moment.utc(parsed.expiration_date, "YYYYMMDD").isBefore(moment.utc())
      ) {
        this.setState({ error: "expired" });
        return;
      }
    }

    this.setState({ loading: true }, () => {
      canvas.toBlob(
        (blob) => {
          this.props
            .onFinish(blob, raw, parsed)
            .then()
            .catch((e) => this.setState({ error: "upload" }));
        },
        "image/jpeg",
        0.7
      );
    });
  };

  decodeImg = async (e) => {
    if (this.toolTipTimer) {
      window.clearTimeout(this.toolTipTimer);
    }
    this.setState({ loading: true });
    e.persist();
    const fr = new FileReader();
    fr.onload = (e) => {
      const legacyImage = e.target.result.toString();
      let image = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      image.onload = async () => {
        try {
          if (image.height > image.width) {
            canvas.height = 1024;
            canvas.width = image.width * (1024 / image.height);
          } else {
            canvas.width = 1024;
            canvas.height = image.width * (1024 / image.height);
          }
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const recognizer = await this.pRecognizer;
          let results = await recognizer.recognize(canvas);
          for (let result of results) {
            if (result.lineResults.length >= 2) {
              this.onScan({ result: result.lineResults, canvas: canvas });
              return;
            }
          }
          if (!results.length) {
            this.setState({
              loading: false,
              cantRead: true,
              tempFile: e,
            });
          }
        } catch (ex) {
          let errMsg;
          if (ex.message.includes("network connection error")) {
            errMsg =
              "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
          } else {
            errMsg = ex.message || ex;
          }
          console.log(errMsg);
          this.setState({
            loading: false,
            cantRead: true,
            tempFile: e.target.files[0],
          });
        }
      };
      image.src = legacyImage;
    };
    fr.readAsDataURL(e.target.files[0]);
  };

  tips = (
    <>
      <p>I can't read your passport</p>
      <div>
        Please try changing the angle and lighting to provide a clear and
        unobstructed view of Machine Readable Zone on the ID page of your
        passport
      </div>
      <img
        style={{ maxWidth: 300, width: "100%" }}
        src={PassportIllustration}
      />
    </>
  );

  tooltip = () => {
    if (window.streamSupported) {
      return (
        <div className={"errorMessage"}>
          {this.tips}
          <div
            className={"idScanSelector"}
            onClick={() => {
              this.setState({
                loading: false,
                showToolTip: false,
              });
            }}
          >
            <p>Okay!</p>
          </div>
        </div>
      );
    }
  };

  cantReadModal = () => {
    return (
      <div className={"errorMessage"}>
        {this.tips}
        <div
          className={"idScanSelector"}
          onClick={() => {
            this.setState({
              loading: false,
              cantRead: false,
            });
          }}
        >
          <p>Try Again</p>
        </div>

        <div
          className={"idScanSelector"}
          onClick={() => {
            this.setState({
              loading: true,
              cantRead: false,
            });
            const fr = new FileReader();
            fr.onload = (e) => {
              const legacyImage = e.target.result.toString();
              let image = new Image();
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              image.onload = () => {
                canvas.height = image.height;
                canvas.width = image.width;
                ctx.drawImage(image, 0, 0);
                this.onScan({ result: "manual", canvas: canvas, manual: true });
              };
              image.src = legacyImage;
            };
            fr.readAsDataURL(this.state.tempFile);
          }}
        >
          <p>Submit anyways</p>
        </div>
      </div>
    );
  };

  render = () => {
    const { instruction, cantRead, error, showToolTip, loading } = this.state;
    const height = this.props.hideTop?"100vh": "calc(100vh - 180px)"
    return (
      <div style={{ height: height, display: 'flex', flexDirection: 'column' }}>
        <input
          type="file"
          accept="image/*"
          id="file-upload-button"
          hidden
          onChange={this.decodeImg}
          onClick={(event) => {
            if (this.toolTipTimer) {
              window.clearTimeout(this.toolTipTimer);
            }
            event.target.value = null;
          }}
        />
        <h3>
            Scan your passport
        </h3>
        <div className="close-icon" onClick={this.handleBackToSelectView} />
        <div className="center-frame">
          {!loading && window.streamSupported && (
            <>
              <VideoRecognizer onScan={this.onScan} />
            </>
          )}
          {!window.streamSupported && (
            <div style={{ padding: "2rem" }}>
              Your have declined camera access for scanning your Passport,
              please upload manually
              <label htmlFor="file-upload-button" className={"idScanSelector"}>
                <img style={{ height: 80 }} src={CameraIcon} /> <br />
                <p>Upload File</p>
              </label>
            </div>
          )}
        </div>
        {window.streamSupported && (
          <div className={"problems"}>
            Having Problems?
            <label htmlFor="file-upload-button" className={"idScanSelector"}>
              <p>Upload File</p>
            </label>
          </div>
        )}
        {(error || showToolTip || loading || cantRead || instruction) && (
          <div className={"webcam-loading-overlay"}>
            {cantRead ? (
              this.cantReadModal()
            ) : error ? (
              this.getErrorMessage()
            ) : showToolTip ? (
              this.tooltip()
            ) : instruction ? (
              this.instruction()
            ) : loading ? (
              <div className={"errorMessage"}>
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <h5>Loading...</h5>
                <p>
                  This might take a moment, please do not close this window.
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
}
