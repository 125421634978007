import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import classNames from "classnames";

import QRCode from "qrcode.react";

import { ID_SCANNER_ROOT, SCANNER_API_ROOT } from "./config";
import moment from "moment";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { sendText } from "./api";
import { CircularProgress } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { isBrowser } from "react-device-detect";
import axios from "axios";

function useVersion() {
  const { search } = useLocation();

  return React.useMemo(() => {
    const q = new URLSearchParams(search);
    let version = "1";
    if (q.get("v")) {
      version = q.get("v");
    }

    if (version === "1"){
      return ""
    }
    return version
  }, [search]);
}

const StartScanning = () => {
  const { scanID } = useParams();
  const history = useHistory();
  const [config, setConfig] = useState({});
  const [action, setAction] = useState("welcome");
  const [showProgressTracker, setShowProgressTracker] = useState(false);
  const [wsTimeout, setWsTimeout] = useState(true);
  const [wsRetries, setWsRetries] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [phoneNum, setPhoneNum] = useState();
  const [textLoading, setTextLoading] = useState(false);
  const [textDone, setTextDone] = useState(false);
  const [hideTop, setHideTop] = useState(false);
  const [css, setCSS] = useState("");
  const [title, setTitle] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [finishSettings, setFinishSettings] = useState({});
  const version = useVersion();


  const wsUpdate = (isWS, message) => {
    if (updateTimer) {
      window.clearTimeout(updateTimer);
      updateTimer = null;
      setLastUpdated(moment());
    }
    if (message === "mobile") {
      setAction("waiting");
      axios.post(
        "https:" + SCANNER_API_ROOT + "/scanner/config/" + scanID,
        window.cfg
      );
    }

    if (message === "desktop") {
      setAction("done");
    }
  };

  let updateTimer = null;
  const checkLastUpdated = () => {
    const fifteenSecondsAgo = moment().subtract(15, "seconds");
    if (lastUpdated.isBefore(fifteenSecondsAgo)) {
      wsUpdate(true, "");
    }
  };

  const onWSMessage = (e) => {
    try {
      const data = JSON.parse(e.data);
      if (data) {
        if (data.type === "message") {
          const fiveSecondsAgo = moment().subtract(5, "seconds");
          if (lastUpdated.isBefore(fiveSecondsAgo)) {
            wsUpdate(true, data.message);
          } else {
            if (!updateTimer) {
              updateTimer = window.setTimeout(() => {
                wsUpdate(true, data.message);
              }, 5000);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const startWS = (websocketServerLocation) => {
    window.startScannerWS = new WebSocket(websocketServerLocation);
    window.startScannerWS.onmessage = (e) => {
      onWSMessage(e);
    };
    window.startScannerWS.onclose = () => {
      setWsRetries(wsRetries + 1);
      if (wsRetries >= 10) {
        window.location.reload();
      }
      setWsTimeout(
        setTimeout(() => {
          const wsRoute = process.env.REACT_APP_WS_ROUTE.replace(/\/$/, "");
          startWS("wss:" + wsRoute + "/scanner/" + scanID);
        }, 5000)
      );
    };
    window.startScannerWS.onopen = () => {
      setWsRetries(0);
    };
  };

  let done = false;
  const listener = (e) => {
    // checkorigin
    if (e.data) {
      if (e.data.type === "config") {
        const cfg = e.data.config;
        setConfig(cfg);
        window.cfg = cfg;
        if (cfg.title) {
          setTitle(cfg.title);
        }
        if (cfg.hideTop) {
          setHideTop(cfg.hideTop);
        }
        if (cfg.descriptions) {
          setDescriptions(cfg.descriptions);
        }
        if (cfg.customization && cfg.customization.welcome) {
          const welcome = cfg.customization.welcome;
          setTitle(welcome.header);
          setDescriptions([welcome.body]);
        }
        if (cfg.customization && cfg.customization.finish) {
          const finish = cfg.customization.finish;
          if (finish.primary) {
            setFinishSettings(finish.primary);
          }
        }
        if (cfg.userDetails && cfg.userDetails.smsNumber) {
          setPhoneNum(cfg.userDetails.smsNumber);
        }
        if (cfg.customization && cfg.customization.css) {
          setCSS(cfg.customization.css);
        }
      }
    }
  };
  useEffect(() => {
    if (!scanID) {
      return;
    }

    let checkTimeoutInterval;
    if (isBrowser) {
      const wsRoute = process.env.REACT_APP_WS_ROUTE.replace(/\/$/, "");
      // need to implement sending/receiving messages from scanner websocket
      // scanner-api has websocket route at scanner/{user_id}
      if (!window.startScannerWS) {
        startWS("wss:" + wsRoute + "/scanner/" + scanID);
      }

      checkTimeoutInterval = window.setInterval(() => {
        checkLastUpdated();
      }, 15000);
    }

    window.addEventListener("message", (e) => {
      listener(e);
    });
    if (window.parent) {
      window.parent.postMessage("ready", "*"); // TODO: Register domain on scan init and record on scan job.  when retrieivng scan job use that domain for the target origin
    }

    return () => {
      done = true;
      if (isBrowser) {
        if (checkTimeoutInterval) {
          window.clearInterval(checkTimeoutInterval);
        }
        if (window.startScannerWS) {
          window.startScannerWS.onclose = () => {};
          window.startScannerWS.close();
          window.startScannerWS = null;
        }
        if (wsTimeout) {
          window.clearTimeout(wsTimeout);
        }
      }
    };
  }, [scanID]);

  let inside = <div />;
  let waiting = (
    <div className="waiting-wrapper">
      <p className="courier-waiting-text">
        Please continue scanning your ID on your mobile device
      </p>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );

  let selectID = () => {
    return (
      <div className="waiting-wrapper" style={{ maxWidth: 550 }}>
        <h2
          className="courier-waiting-text"
          style={{ maxWidth: "80%", margin: "40px auto" }}
        >
          Continue on your mobile
        </h2>
        <div style={{ maxWidth: "80%", margin: "40px auto" }}>
          <p>
            It's much easier to scan your ID on a phone, please scan the QR code
            or use the following button to text yourself a secure link
          </p>
        </div>
        <QRCode value={"https:" + ID_SCANNER_ROOT.replace("id.heyemjay.com", `id${version}.heyemjay.com`) + "/scanner/" + scanID} />
        <div className="other-id-type">
          <h4 className="or-line">
            <span>OR</span>
          </h4>
        </div>
        <div className="mobile-input-container">
          <PhoneInput
            name="mobile"
            label="Mobile No."
            placeholder="Mobile Number"
            displayInitialValueAsLocalNumber
            required
            defaultCountry="US"
            value={phoneNum}
            onChange={setPhoneNum}
            error={
              phoneNum
                ? isValidPhoneNumber(phoneNum)
                  ? undefined
                  : "Invalid phone number"
                : ""
            }
          />
          <div
            className="idScanSelector"
            onClick={() => {
              setTextLoading(true);
              sendText(scanID, phoneNum)
                .then(() => {
                  setTextLoading(false);
                  setTextDone(true);
                })
                .catch((e) => {
                  setTextLoading(false);
                  setTextDone(true);
                });
            }}
          >
            <div style={{ padding: 5, width: "100%" }}>
              <div>Send</div>
              {textLoading && (
                <div className={"buttonOverlay"}>
                  <CircularProgress size={"1rem"} />
                </div>
              )}
              {textDone && (
                <div className={"buttonOverlay"}>
                  <CheckCircleOutlineIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const next = () => {
    if (isBrowser) {
      setAction("start_scanning");
    } else {
      history.push({
        pathname: `/scanner/${scanID}`,
        state: {
          noHandOff: true,
          hideTop: hideTop,
          onFinish: config.onFinish,
          finishMessage: config.finishMessage,
          customization: config.customization,
        },
      });
    }
  };

  switch (action) {
    case "welcome":
      inside = (
        <div className="waiting-wrapper" style={{ maxWidth: 550 }}>
          <h2
            className="courier-waiting-text"
            style={{ maxWidth: "80%", margin: "40px auto" }}
          >
            {title}
          </h2>
          <div style={{ maxWidth: "80%", margin: "40px auto" }}>
            {descriptions.map((d, i) => (
              <p key={`description_${i}`}>{d}</p>
            ))}
          </div>
          <div className="idScanSelector" onClick={next}>
            <div style={{ padding: 15, width: "100%" }}>Continue</div>
          </div>
        </div>
      );
      break;
    case "start_scanning":
      inside = selectID();
      break;
    case "done":
      if (window.parent) {
        window.parent.postMessage("finish", "*"); // TODO: Register domain on scan init and record on scan job.  when retrieivng scan job use that domain for the target origin
      }
      inside = (
        <div className="waiting-wrapper">
          <h2
            className="courier-waiting-text"
            style={{ maxWidth: "80%", margin: "40px auto" }}
          >
            {finishSettings.header ? finishSettings.header : "That's all!"}
          </h2>
          <div style={{ maxWidth: "80%", margin: "40px auto" }}>
            <h3 style={{ fontWeight: "normal" }}>
              {finishSettings.body
                ? finishSettings.body
                : "We're finalizing some details and you'll be ready to go!"}
            </h3>
          </div>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
      break;
    case "waiting":
    default:
      inside = waiting;
  }

  return (
    <div className="appContainer">
      {css && <link rel="stylesheet" type="text/css" href={css} />}
      {!hideTop && (
        <div className="topBar">
          <figure className="emjay-logo" />
        </div>
      )}
      <div
        className="content"
        style={action === "thankyou" ? { background: "#2BB6A2" } : {}}
      >
        <React.Fragment>
          {showProgressTracker && (
            <div className="progress-tracker">
              <div
                className={classNames({
                  "progress-tracker__indicator": true,
                  complete: ["thankyou"].includes(action),
                  "in-progress": action === "scan_id",
                })}
              >
                <p className={classNames({ selected: action === "scan_id" })}>
                  Scan ID
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
      {inside}
    </div>
  );
};

export default StartScanning;
