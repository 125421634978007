import React, { useEffect } from "react";
import FrontIDScan from "./FrontIDScan";
import BackIDScan from "./BackIDScan";
import SelfieScan from "./SelfieScan";

import { Storage } from "aws-amplify";

import axios from "axios";
import { SCANNER_API_ROOT } from "./config";
import moment from "moment";
import Signature from "./Signature";
import PassportScanner from "./PassportScanner";

const level = "public";

Storage.configure({
  customPrefix: {
    public: "public/",
    protected: "protected/",
    private: "private/",
  },
  level: level,
});

function IDScan(props) {
  const { hideTop } = props;
  const [pdf417, setPdf417] = React.useState("");
  const [frontImageUrl, setFrontImageUrl] = React.useState("");
  const [passportImageUrl, setPassportImageUrl] = React.useState("");
  const [selfie, setSelfie] = React.useState("");
  const [signature, setSignature] = React.useState("");
  const [backImageUrl, setBackImageUrl] = React.useState("");
  const [selfieImageUrl, setSelfieImageUrl] = React.useState("");
  const [task, setTask] = React.useState("select");
  const [frontIdCaptured, setFrontIdCaptured] = React.useState(false);
  const [passportCaptured, setPassportCaptured] = React.useState(false);
  const [backIdCaptured, setBackIdCaptured] = React.useState(false);
  const [selfieCaptured, setSelfieCaptured] = React.useState(false);
  const [signatureCapture, setSignatureCaptured] = React.useState(false);
  const [isCaptured, setIsCaptured] = React.useState(false);
  const [haveProblem, setHaveProblem] = React.useState(false);

  const {
    scanID,
    idType,
    steps,
    awsUser,
    s3Bucket,
    customization,
    userID,
    partner,
    supportEmail,
  } = props;

  useEffect(() => {
    if (task === "select") {
      props.updateTrackerUIState(true);
    } else {
      props.updateTrackerUIState(false);
    }
    const selfieSatisfied = !steps.includes("selfie") || selfie; // either selfie not required, or satisfied
    const signatureSatisfied = !steps.includes("signature") || signature; // either selfie not required, or satisfied
    switch (idType) {
      case "passport":
        if (passportCaptured && selfieSatisfied && signatureSatisfied) {
          setIsCaptured(true);
        }
        break;
      default:
        if (pdf417 && frontImageUrl && selfieSatisfied && signatureSatisfied) {
          setIsCaptured(true);
        }
    }
  }, [
    pdf417,
    frontImageUrl,
    passportCaptured,
    selfie,
    signature,
    task,
    props,
    steps,
  ]);

  useEffect(() => {
    Storage.configure({
      customPrefix: {
        public: "public/",
        protected: "protected/",
        private: "private/",
      },
      level: "public",
    });
  }, [awsUser]);

  const handleCapturePassport = () => {
    setTask("passport");
  };

  const handleCaptureFrontId = () => {
    setTask("front");
  };

  const handleCaptureBackId = () => {
    setTask("back");
  };

  const handleCaptureSelfie = () => {
    setTask("selfie");
  };
  const handleCaptureSignature = () => {
    setTask("signature");
  };

  let frontFailedOnce = false;
  const onScanFrontFinish = (data) => {
    return fetch(data).then((res) => {
      res.blob().then((file) => {
        upload("id_front", file)
          .then((res) => {
            setFrontImageUrl(res.key);
            setTask("select");
            setFrontIdCaptured(true);
          })
          .catch((err) => {
            if (!frontFailedOnce) {
              frontFailedOnce = true;
            }
            throw err;
            // console.log(err);
          });
      });
    });
  };

  const passportListener = (imageData, raw, parsed) => {
    upload("passport", imageData)
      .then((res) => {
        setTask("select");
        setPassportImageUrl(res.key);
        setPassportCaptured(true);
        axios.post("https:" + SCANNER_API_ROOT + "/scanner/passport", {
          scan_id: scanID,
          storage: "s3",
          bucket: s3Bucket,
          filepath: level + "/" + res.key,
          kyc_info: {
            document_number: parsed.document_number,
            document_expiration: moment.utc(parsed.expiration_date, "YYYYMMDD"),
            id_first_name: parsed.first_name,
            id_last_name: parsed.last_name,
            sex: parsed.gender,
            dob: moment.utc(parsed.birthday, "YYYYMMDD"),
            issuing_country: parsed.issuing_country,
          },
          barcode_data: raw,
        });
      })
      .catch((err) => {
        throw err;
        // console.log(err);
      });
  };

  const onScanPassportFinish = (data) => {
    window.postMessage(
      {
        cmd: "process",
        image: data,
      },
      "*"
    );
  };

  const onSignatureComplete = (data) => {
    fetch(data).then((res) => {
      res.blob().then((file) => {
        upload("signature", file)
          .then((res) => {
            setSignature(res.key);
            setTask("select");
            setSignatureCaptured(true);
          })
          .catch((err) => {
            // console.log(err);
          });
      });
    });
  };
  const onScanSelfieFinish = (data) => {
    fetch(data).then((res) => {
      res.blob().then((file) => {
        upload("selfie", file)
          .then((res) => {
            setSelfieImageUrl(res.key);
            setSelfie(res.key);
            setTask("select");
            setSelfieCaptured(true);
          })
          .catch((err) => {
            throw err;
            // console.log(err);
          });
      });
    });
  };

  const onScanBackFinish = (imageData, pdf417) => {
    return upload("id_back", imageData)
      .then((res) => {
        setTask("select");
        setBackIdCaptured(true);
        setBackImageUrl(res.key);
        setPdf417(pdf417);
        // console.log("s3 result object")
        // console.log(res)
        axios
          .post("https:" + SCANNER_API_ROOT + "/scanner/id_back", {
            scan_id: scanID,
            bucket: s3Bucket,
            storage: "s3",
            filepath: level + "/" + res.key,
            barcode_data: pdf417,
          })
          .then((result) => {
            // console.log(result)
          });
      })
      .catch((err) => {
        throw err;
        // console.log(err);
      });
  };

  const upload = (fileType, data) => {
    // upload to s3
    // const filename = scanID + "/" + uuidv4() + "-" + fileType + ".jpg"
    const filename = scanID + "/" + fileType + ".jpg"; // Since the scan name is unique now, maybe we don't need unique ID for filenames??
    return Storage.put(filename, data, {
      contentType: "image/jpeg",
      progressCallback: (progress) => {
        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    })
      .then((result) => {
        // console.log(result)
        return result;
      })
      .catch((err) => {
        console.log("error uploading", err);
        return err;
      });
  };

  // useEffect( () => {
  //
  //   if (((frontIdCaptured && backIdCaptured) || passportCaptured) && selfieCaptured) {
  //     props.sendResponse();
  //   }
  // }, [frontIdCaptured, backIdCaptured, passportCaptured, selfieCaptured])

  useEffect(() => {
    if (isCaptured) {
      props.sendResponse();
    }
  }, [isCaptured]);
  const selector = (
    <React.Fragment>
      <div className="waiting-wrapper">
        <h2 className="courier-waiting-text"> Let's Capture your I.D.</h2>
        <p style={{ marginBottom: "50px", padding: "0 30px" }}>
          Please scan{" "}
          {idType === "passport"
            ? "your passport"
            : "both the back and front side of your driver's license"}{" "}
          and provide a clear selfie.
        </p>
        {idType === "passport" && (
          <div
            className="idScanSelector"
            onClick={() => {
              handleCapturePassport();
            }}
          >
            <div
              className={`capture-icon ${
                passportCaptured ? "capture-icon__complete" : ""
              }`}
            />
            <p>Capture passport</p>
          </div>
        )}
        {idType === "id_card" && (
          <div>
            <div
              className="idScanSelector"
              onClick={() => {
                handleCaptureFrontId();
              }}
            >
              <div
                className={`capture-icon ${
                  frontIdCaptured ? "capture-icon__complete" : ""
                }`}
              />
              <p>Capture front of ID</p>
            </div>
            <div
              className="idScanSelector"
              onClick={() => {
                handleCaptureBackId();
              }}
            >
              <div
                className={`capture-icon ${
                  backIdCaptured ? "capture-icon__complete" : ""
                }`}
              />
              <p>Capture back of ID</p>
            </div>
          </div>
        )}
        {steps.includes("selfie") && (
          <div
            className="idScanSelector"
            onClick={() => {
              handleCaptureSelfie();
            }}
          >
            <div
              className={`capture-icon ${
                selfieCaptured ? "capture-icon__complete" : ""
              }`}
            />
            <p>Capture selfie</p>
          </div>
        )}
        {steps.includes("signature") && (
          <div
            className="idScanSelector"
            onClick={() => {
              handleCaptureSignature();
            }}
          >
            <div
              className={`signature-icon ${
                signatureCapture ? "capture-icon__complete" : ""
              }`}
            />
            <p>Sign</p>
          </div>
        )}
        <div className="other-id-type">
          <h4 className="or-line">
            <span>OR</span>
          </h4>
          <div onClick={props.back}>Select another ID type</div>
        </div>
      </div>
    </React.Fragment>
  );

  const backToSelect = () => {
    setTask("select");
  };

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  const sendEmail = () => {
    const subjectLine = encodeURIComponent(`ID Problem (Ref: ${userID})`);
    const content = encodeURIComponent(`Hey ${capitalize(
      partner
    )} CS, Here are the 3 attachments needed for my delivery!
1. Front of ID
2. Back of ID
3. Selfie of me holding my ID

Thanks!`);
    const email = supportEmail ? supportEmail : "support@heyemjay.com";
    window.open(`mailto:${email}?subject=${subjectLine}&body=${content}`);
  };
  const problem = () => {
    return (
      <div className={"problemModal"}>
        <div className={"closeButton"} onClick={() => setHaveProblem(false)}>
          ×
        </div>
        <p>Having problems scanning your ID?</p>
        <p>
          Please use the button below to send us an email with the following
          attachments:
        </p>
        <p style={{ textAlign: "left" }}>
          <ul>
            <li>Front of your ID</li>
            <li>Back of your ID</li>
            <li>A selfie of you holding your ID</li>
          </ul>
        </p>

        <div
          className={"idScanSelector"}
          onClick={() => {
            sendEmail();
          }}
        >
          <p>Email Us</p>
        </div>
        <div className="other-id-type" style={{ margin: "20px auto" }}>
          <div onClick={() => setHaveProblem(false)}>Never mind</div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {task === "select" && selector}
      {task === "passport" && (
        <PassportScanner
          backToSelect={backToSelect}
          onFinish={passportListener}
          type={"passport"}
          hideTop={hideTop}
        />
      )}
      {task === "front" && (
        <FrontIDScan
          backToSelect={backToSelect}
          onFinish={onScanFrontFinish}
          type={"front"}
          hideTop={hideTop}
        />
      )}
      {task === "back" && (
        <BackIDScan
          backToSelect={backToSelect}
          onFinish={onScanBackFinish}
          hideTop={hideTop}
        />
      )}
      {task === "selfie" && (
        <SelfieScan
          backToSelect={backToSelect}
          onFinish={onScanSelfieFinish}
          hideTop={hideTop}
        />
      )}
      {task === "signature" && (
        <Signature
          onFinish={onSignatureComplete}
          customization={customization ? customization.signature : null}
          hideTop={hideTop}
        />
      )}
    </div>
  );
}

export default IDScan;
