import React, { useEffect } from "react";
import Webcam from "react-webcam";
import PassportOverlay from "./assets/images/passport_overlay.png";
import CameraIcon from "./assets/images/camera.svg";

export default function FrontIDScan(props) {
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const videoConstraints = React.useMemo(() => {
    const iPhoneWideAngleCamera = devices.find(
      (c) => c.label === "Back Ultra Wide Camera"
    );
    if (iPhoneWideAngleCamera) {
      return {
        deviceId: iPhoneWideAngleCamera.deviceId,
      };
    }
    return {
      facingMode: "environment",
    };
  }, [devices]);
  const genericError = (
    <div className={"errorMessage"}>
      <p>Error reading your passport, please make sure:</p>
      <ul>
        <li>The image is free of glare</li>
        <li>No part of the passport is covered</li>
        <li>All text are legible</li>
      </ul>
      <div
        className={"idScanSelector"}
        onClick={() => {
          setError(null);
          setLoading(false);
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  const underageError = (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        We are not able to serve customers under the age of 21, or 18 if you
        have a medical recommendation
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          setError(null);
          setLoading(false);
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  const expirationError = (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        Looks like your passport has expired, please scan a current document
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          setError(null);
          setLoading(false);
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );
  const webcamRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [errorCount, setErrorCount] = React.useState(0);
  const [problems, setProblems] = React.useState(false);
  const [tempImage, setTempImage] = React.useState("");
  const [legacyImage, setLegacyImage] = React.useState(null);
  const { type } = props;
  const passportListener = (e) => {
    if (e.data && e.data.type === "result" && e.data.result) {
      if (
        e.data.result.error ||
        !e.data.result.parsed ||
        (e.data.result.parsed && e.data.result.parsed.error)
      ) {
        if (e.data.result.parsed && e.data.result.parsed.error) {
          if (e.data.result.parsed.error === "underage") {
            setError("underage");
          } else if (e.data.result.parsed.error === "expired") {
            setError("expired");
          } else {
            setError("generic");
          }
        } else {
          setError("generic");
        }
        setErrorCount((errorCount) => errorCount + 1);
      } else {
        setUploading(true);
      }
    }
  };
  useEffect(() => {}, []);
  useEffect(() => {
    if (type === "passport") {
      window.addEventListener("message", passportListener);
      return () => {
        window.removeEventListener("message", passportListener);
      };
    }
  }, [type]);

  useEffect(() => {
    if (tempImage) {
      window.setTimeout(() => {
        console.log("PROPS", props);
        props
          .onFinish(tempImage)
          .catch((e) => this.setState({ error: "upload" }));
      }, 250);
    }
  }, [tempImage]);

  const capture = React.useCallback(() => {
    setLoading(true);
    const imageSrc = webcamRef.current.getScreenshot();
    setTempImage(imageSrc);
  }, [webcamRef]);

  const legacyCapture = React.useCallback(() => {
    setLoading(true);
    setTempImage(legacyImage);
  }, [legacyImage]);

  const selectorClassname =
    loading || (!window.streamSupported && !legacyImage)
      ? "idScanSelector capture disabled"
      : "idScanSelector capture";

  const legacyUploadClassname = legacyImage
    ? "custom-recapture"
    : "custom-file-upload";

  const uploadError = (
    <div className={"errorMessage"}>
      <p>Error uploading your ID.</p>
      <span>An error occurred while uploading your ID, please try again.</span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          setError(null);
          setLoading(false);
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  const getErrorMessage = () => {
    switch (error) {
      case "underage":
        return underageError;
      case "expired":
        return expirationError;
      case "upload":
        return uploadError;
      default:
        return genericError;
    }
  };
  const legacyFileUploadChange = (input, problem) => {
    if (input.target.files && input.target.files[0]) {
      const fr = new FileReader();
      fr.onload = function (e) {
        if (problem) {
          setLoading(true);
          setTempImage(e.target.result.toString());
        } else {
          setLegacyImage(e.target.result.toString());
        }
      };
      fr.readAsDataURL(input.target.files[0]);
    }
  };
  const height = props.hideTop ? "100vh" : "calc(100vh - 180px)";

  return (
    <React.Fragment>
      <div className="close-icon" onClick={() => props.backToSelect()} />
      <div style={{ height: height, display: "flex", flexDirection: "column" }}>
        <h3>
          {type === "passport"
            ? "Scan your passport"
            : "Scan the front of your ID"}
        </h3>
        <div className="center-frame">
          <div className={"webcam-container"}>
            {loading ? (
              <div>
                <img src={tempImage} className={"webcam-preview"} />
                <div className={"webcam-loading-overlay"}>
                  {error ? (
                    getErrorMessage()
                  ) : (
                    <div className={"errorMessage"}>
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <h5>
                        {uploading || type !== "passport"
                          ? "Processing..."
                          : "Reading your passport..."}
                      </h5>
                      <p>
                        This might take a moment, please do not close this
                        window.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {window.streamSupported && !legacyImage ? (
                  <>
                    <Webcam
                      className={"webcam-preview"}
                      // style={{ width: '100vw', position: 'absolute', bottom:0, left:0}}
                      audio={false}
                      ref={webcamRef}
                      screenshotQuality={1}
                      screenshotFormat="image/jpeg"
                      forceScreenshotSourceSize
                      videoConstraints={videoConstraints}
                    />
                    {type === "passport" && (
                      <div className="passport-overlay">
                        <img src={PassportOverlay} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {legacyImage && (
                      <img
                        className={"webcam-preview-legacy"}
                        src={legacyImage}
                      />
                    )}
                    {!problems && (
                      <label
                        htmlFor="file-upload"
                        className={legacyUploadClassname}
                      >
                        {!legacyImage &&
                          (type === "passport" ? (
                            <img
                              style={{ width: "80%" }}
                              src={PassportOverlay}
                            />
                          ) : (
                            <img style={{ height: 80 }} src={CameraIcon} />
                          ))}
                        {legacyImage
                          ? "Retake picture"
                          : type === "passport"
                          ? "Tap to take picture of the ID page of your passport, be sure the code at the bottom is not obstructed."
                          : "Launch camera to take a picture"}
                      </label>
                    )}
                    <input
                      id="file-upload"
                      onChange={(e) => legacyFileUploadChange(e, false)}
                      type="file"
                      accept="image/*"
                      capture="environment"
                    />
                  </>
                )}
              </div>
            )}
          </div>

          {window.streamSupported && !legacyImage ? (
            <div
              className={selectorClassname}
              onClick={() => {
                if (loading) return;
                capture();
              }}
            >
              <p>Capture photo</p>
            </div>
          ) : (
            <div
              className={selectorClassname}
              onClick={() => {
                if (!legacyImage) return;
                if (loading) return;
                legacyCapture();
              }}
            >
              <p>Use photo</p>
            </div>
          )}
        </div>
        <div className={"problems"}>
          Having Problems?
          <input
            type="file"
            accept="image/*"
            id="file-upload-button"
            hidden
            onChange={(e) => legacyFileUploadChange(e, true)}
            onClick={(event) => {
              setProblems(true);
              event.target.value = null;
            }}
          />
          <label htmlFor="file-upload-button" className={"idScanSelector"}>
            <p>Upload File</p>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}
