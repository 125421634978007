import React, { Component } from "react";
import { BarcodeReader, EnumBarcodeFormat } from "dynamsoft-javascript-barcode";
import VideoDecode from "./PDF417VideoDecode/VideoDecode";

import DLOverlay from "./assets/images/dl_overlay.png";
import moment from "moment";
import CameraIcon from "./assets/images/camera.svg";
var aamva = require("./aamva");

BarcodeReader.license = process.env.REACT_APP_DBR_KEY;
BarcodeReader.engineResourcePath =
  "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.3.1/dist/";

export default class BackIDScan extends Component {
  state = {
    pause: false,
    loading: false,
    tempImage: "",
    error: "",
    ready: false,
    showToolTip: false,
  };

  pReader = null;
  toolTipTimer = null;

  async componentDidMount() {
    try {
      await BarcodeReader.loadWasm();
      this.setState({ ready: true }, async () => {
        this.pReader = await BarcodeReader.createInstance();
        let scanSettings = await this.pReader.getRuntimeSettings();
        scanSettings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
        await this.pReader.updateRuntimeSettings(scanSettings);

        if (window.streamSupported) {
          if (!this.state.warned && !this.toolTipTimer) {
            this.toolTipTimer = window.setTimeout(() => {
              this.toolTipTimer = null;
              this.setState({
                warned: true,
                loading: false,
                showToolTip: true,
              });
            }, 15000);
          }
        }
      });
    } catch (ex) {
      alert(ex);

      throw ex;
    }
  }
  genericError = () => (
    <div className={"errorMessage"}>
      <p>Error reading your ID, please make sure:</p>
      <ul>
        <li>The image is free of glare</li>
        <li>No part of the ID is covered</li>
        <li>The barcode is clear and not blurry</li>
      </ul>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  underageError = () => (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        We are not able to serve customers under the age of 21, or 18 if you
        have a medical recommendation
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  expirationError = () => (
    <div className={"errorMessage"}>
      <p>Uh oh!</p>
      <span>
        Looks like your ID has expired, please scan a current document
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  handleBackToSelectView = () => {
    this.setState({ pause: true });
    this.props.backToSelect();
  };

  uploadError = () => (
    <div className={"errorMessage"}>
      <p>Error uploading your ID.</p>
      <span>
        An error occurred while uploading your ID, please try again.
      </span>
      <div
        className={"idScanSelector"}
        onClick={() => {
          this.setState({ error: false, loading: false });
        }}
      >
        <p>Try again</p>
      </div>
    </div>
  );

  getErrorMessage = () => {
    switch (this.state.error) {
      case "underage":
        return this.underageError();
      case "expired":
        return this.expirationError();
      case "upload":
        return this.uploadError();
      default:
        return this.genericError();
    }
  };

  // getSingleImageModeSettings = () => {
  //   return {
  //     desktop: {
  //       usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
  //     },
  //     mobile: {
  //       usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
  //     },
  //   };
  // };

  onScan = ({ result, canvas, manual }) => {
    if (this.toolTipTimer) {
      window.clearTimeout(this.toolTipTimer);
    }
    let parsed = {};
    if (!manual) {
      parsed = aamva.newPdf417(result);
      if (!parsed.birthday) {
        return;
      }
      if (
        moment.utc().diff(moment.utc(parsed.birthday, "YYYYMMDD"), "years") < 18
      ) {
        this.setState({ error: "underage" });
        return;
      }
      if (
        parsed.expiration_date &&
        moment.utc(parsed.expiration_date, "YYYYMMDD").isBefore(moment.utc())
      ) {
        this.setState({ error: "expired" });
        return;
      }
    }

    this.setState({ loading: true }, () => {
      canvas.toBlob(
        (blob) => {
          this.props.onFinish(blob, result).catch((e) => this.setState({ error: "upload" }));
          ;
        },
        "image/jpeg",
        0.7
      );
    });
  };

  decodeImg = async (e) => {
    if (this.toolTipTimer) {
      window.clearTimeout(this.toolTipTimer);
    }
    this.setState({ loading: true });
    e.persist();
    try {
      let results = await this.pReader.decode(e.target.files[0]);
      for (let result of results) {
        if (result.barcodeFormatString !== "PDF417") {
          continue;
        }
        const fr = new FileReader();
        fr.onload = (e) => {
          const legacyImage = e.target.result.toString();
          let image = new Image();
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          image.onload = () => {
            canvas.height = image.height;
            canvas.width = image.width;
            ctx.drawImage(image, 0, 0);
            this.onScan({ result: result.barcodeText, canvas: canvas });
          };
          image.src = legacyImage;
        };
        fr.readAsDataURL(e.target.files[0]);
        return;
      }
      if (!results.length) {
        this.setState({
          loading: false,
          cantRead: true,
          tempFile: e.target.files[0],
        });
      }
    } catch (ex) {
      let errMsg;
      if (ex.message.includes("network connection error")) {
        errMsg =
          "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message || ex;
      }
      console.log(errMsg);
      this.setState({
        loading: false,
        cantRead: true,
        tempFile: e.target.files[0],
      });
    }
  };

  tip = (
    <>
      <p>I can't read your ID</p>
      <span>
        Please try changing the angle and lighting to provide a clear and
        unobstructed view of the data code block on the back of your ID.
      </span>
    </>
  );
  tooltip = () => {
    if (window.streamSupported) {
      return (
        <div className={"errorMessage"}>
          {this.tip}
          <div
            className={"idScanSelector"}
            onClick={() => {
              this.setState({
                loading: false,
                showToolTip: false,
              });
            }}
          >
            <p>Okay!</p>
          </div>
        </div>
      );
    }
  };

  cantReadModal = () => {
    return (
      <div className={"errorMessage"}>
        {this.tip}
        <div
          className={"idScanSelector"}
          onClick={() => {
            this.setState({
              loading: false,
              cantRead: false,
            });
          }}
        >
          <p>Try Again</p>
        </div>
        <div
          className={"idScanSelector"}
          onClick={() => {
            this.setState({
              loading: true,
              cantRead: false,
            });
            const fr = new FileReader();
            fr.onload = (e) => {
              const legacyImage = e.target.result.toString();
              let image = new Image();
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              image.onload = () => {
                canvas.height = image.height;
                canvas.width = image.width;
                ctx.drawImage(image, 0, 0);
                this.onScan({ result: "manual", canvas: canvas, manual: true });
              };
              image.src = legacyImage;
            };
            fr.readAsDataURL(this.state.tempFile);
          }}
        >
          <p>Submit anyways</p>
        </div>
      </div>
    );
  };
  render = () => {
    const { cantRead, error, showToolTip, loading } = this.state;
    const height = this.props.hideTop?"calc(100vh - 90px)": "calc(100vh - 270px)"

    return (
      <div>
        <div className="close-icon" onClick={this.handleBackToSelectView} />
        <div style={{  height: height, display: 'flex', flexDirection: 'column' }}>
          <h3>
           Scan the back of your ID
          </h3>
          <div className="center-frame">
            {!loading && window.streamSupported && (
              <>
                <VideoDecode onScan={this.onScan} />
                <div className="passport-overlay">
                  <img src={DLOverlay} />
                </div>
              </>
            )}
            {!window.streamSupported && (
              <div style={{ padding: "2rem" }}>
                Your have declined camera access for scanning your ID, please
                upload manually
                <input
                  type="file"
                  accept="image/*"
                  id="file-upload-button"
                  hidden
                  onChange={this.decodeImg}
                  onClick={(event) => {
                    this.setState({ problems: true });
                    event.target.value = null;
                  }}
                />
                <label htmlFor="file-upload-button" className={"idScanSelector"}>
                  <img style={{ height: 80 }} src={CameraIcon} /> <br />
                  <p>Upload File</p>
                </label>
              </div>
            )}
          </div>
        </div>

        {window.streamSupported && (
          <div className={"problems"}>
            Having Problems?
            <input
              type="file"
              accept="image/*"
              id="file-upload-button"
              hidden
              onChange={this.decodeImg}
              onClick={(event) => {
                if (this.toolTipTimer) {
                  window.clearTimeout(this.toolTipTimer);
                }
                this.setState({ problems: true });
                event.target.value = null;
              }}
            />
            <label htmlFor="file-upload-button" className={"idScanSelector"}>
              <p>Upload File</p>
            </label>
          </div>
        )}
        {(error || showToolTip || loading || cantRead) && (
          <div className={"webcam-loading-overlay"}>
            {cantRead ? this.cantReadModal() : null}
            {error ? this.getErrorMessage() : null}
            {showToolTip ? this.tooltip() : null}
            {loading ? (
              <div className={"errorMessage"}>
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <h5>Loading...</h5>
                <p>
                  This might take a moment, please do not close this window.
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
}
