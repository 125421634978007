import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export default function Signature(props) {
  const canvasRef = React.useRef(null);
  const [isSigned, setIsSigned] = React.useState(false);


  const submit = () => {
    if (isSigned) {
      props.onFinish(
        canvasRef.current.getTrimmedCanvas().toDataURL(),
      );
    }
  };

  const clear = () => {
    if (canvasRef && canvasRef.current) {
      canvasRef.current.clear();
      setIsSigned(false);
    }
  };

  const isEmpty = () => {
    let signed = !canvasRef.current.isEmpty();
    if (signed) {
      setIsSigned(true);
    } else {
      setIsSigned(false);
    }
  };

  const selectorClassname = !isSigned
    ? "idScanSelector disabled"
    : "idScanSelector";
  return (
    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
      {<span className={'declaration'}>{props.customization && props.customization.text ? props.customization.text: 'By signing here, I attest under penalty that I am at least 21 years of age and the documents I upload are true and correct. '}</span>}

      <div style={{position:'relative'}}>
        <SignatureCanvas
          penColor="#3C3C3C"
          ref={canvasRef}
          onEnd={isEmpty}
          canvasProps={{ className: 'sigCanvas' }}
        />
        <div className='bottom-line' />
        {isSigned && <span className={'clear'} onClick={clear}>Clear</span>}
      </div>



      <div
        className={selectorClassname}
        onClick={() => {
          if (!isSigned) return;
          submit();
        }}
      >
        <p>Continue</p>
      </div>

      {/*<div className={submitButtonStyles} onClick={submit}>*/}
      {/*  Complete Delivery*/}
      {/*</div>*/}
    </div>
  );
}
